













import { OrderListItemData } from '@/api/order';
import { Vue, Component, Prop, Inject } from 'vue-property-decorator';
import OrderProduct from '../../comm/OrderProduct.vue';
import OrderButtonGroup from '../../comm/OrderButtonGroup.vue';
import getOrderState, { BaseOrderStateFnKey } from '../../commFunction/getOrderState';
import { ProductTypeEnum } from '@/api/type/base';
@Component({
  name: '',
  components: { OrderProduct, OrderButtonGroup }
})
export default class extends Vue {
  @Prop({ required: true })
  innerData!: OrderListItemData;

  @Inject('reload')
  reload!: (orderCode: string) => void;

  isClick = false;

  activated() {
    if (this.isClick) {
      this.isClick = false;
      this.reload(this.innerData.orderCode);
    }
  }
  get orderStateText() {
    const orderState = getOrderState(this.innerData);
    let text = '';
    if (orderState === BaseOrderStateFnKey.PAYMENT_WAITING) {
      text = '待付款';
    } else if (orderState === BaseOrderStateFnKey.ORDER_CANCEL) {
      text = '已取消';
    } else if (orderState === BaseOrderStateFnKey.DELIVER_WAITING) {
      if (this.innerData.spuType === ProductTypeEnum.PRODUCT_TYPE_PHYSICAL) {
        // 实物
        text = '待发货';
      } else if (this.innerData.spuType === ProductTypeEnum.PRODUCT_TYPE_VIRTUAL) {
        // 虚拟
        text = '待充值';
      }
    } else if (orderState === BaseOrderStateFnKey.DELIVER_IN) {
      if (this.innerData.spuType === ProductTypeEnum.PRODUCT_TYPE_PHYSICAL) {
        // 实物
        text = '发货中';
      } else if (this.innerData.spuType === ProductTypeEnum.PRODUCT_TYPE_VIRTUAL) {
        // 虚拟
        text = '充值中';
      }
    } else if (orderState === BaseOrderStateFnKey.DELIVER_TRANSIT) {
      text = '已发货';
    } else if (orderState === BaseOrderStateFnKey.DELIVER_COMPLETE) {
      text = '已完成';
    } else if (orderState === BaseOrderStateFnKey.AFTER_SALES) {
      text = '售后中';
    } else if (orderState === BaseOrderStateFnKey.AFTER_SALES_COMPLETE) {
      text = '售后完成';
    } else if (orderState === BaseOrderStateFnKey.ORDER_CLOSE) {
      text = '订单关闭';
    }
    return text;
  }

  goDetail() {
    this.$router.push({ name: 'OrderDetail', params: { orderCode: this.innerData.orderCode } });
    this.isClick = true;
  }
}
