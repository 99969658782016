













import { getOrderList, getOrderListItem, OrderListItemData } from '@/api/order';
import { DeliverStateEnum, OrderPayStateEnum, OrderStateEnum } from '@/api/type/base';
import { Vue, Component, Ref, Provide } from 'vue-property-decorator';
import BaseList from '@/components/Base/BaseList/index.vue';
import OrderListItem from './comm/OrderListItem.vue';
@Component({
  name: '',
  components: { BaseList, OrderListItem }
})
export default class extends Vue {
  searchValue = '';
  tabActive = 0;

  @Ref('myList')
  myList!: BaseList;

  onSearch() {
    this.myList.reload();
  }

  @Provide('reload')
  async getOrderListItem(orderCode: OrderListItemData['orderCode']) {
    const index = this.myList.list.findIndex(item => item.orderCode === orderCode);
    const { payload } = await getOrderListItem(orderCode);
    this.myList.list.splice(index, 1, payload);
  }

  get api() {
    return getOrderList;
  }
  get param() {
    return { ...this.tabList[this.tabActive].searchObj, spuName: this.searchValue };
  }

  get tabList() {
    return [
      {
        name: '全部',
        searchObj: {}
      },
      {
        name: '待付款',
        searchObj: {
          deliverState: DeliverStateEnum.DELIVER_STATE_WAIT,
          orderState: OrderStateEnum.ORDER_STATE_CREATE,
          payState: OrderPayStateEnum.PAY_STATE_WAIT
        }
      },
      {
        name: '待发货',
        searchObj: {
          payState: OrderPayStateEnum.PAY_STATE_PAID,
          deliverState: DeliverStateEnum.DELIVER_STATE_WAIT,
          orderState: OrderStateEnum.ORDER_STATE_FINISH
        }
      },
      {
        name: '发货中',
        searchObj: {
          orderState: OrderStateEnum.ORDER_STATE_FINISH,
          deliverState: DeliverStateEnum.DELIVER_STATE_IN
        }
      },
      {
        name: '已发货',
        searchObj: {
          orderState: OrderStateEnum.ORDER_STATE_FINISH,
          deliverState: DeliverStateEnum.DELIVER_STATE_TRANSIT
        }
      },
      {
        name: '已完成',
        searchObj: {
          orderState: OrderStateEnum.ORDER_STATE_FINISH,
          deliverState: DeliverStateEnum.DELIVER_STATE_FINISH
        }
      }
    ];
  }

  created() {
    if (this.$route.query.type) {
      this.tabActive = Number(this.$route.query.type);
    }
  }
}
